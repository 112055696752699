<template>
  <div class="u3d">
    <!-- <div>model3D</div> -->
    <!-- 数据图表 -->
      <div id="largeWin" class="dataWin" :class="closeShow==1?'a':'b'">
            <dataShow :closeShow='closeShow' @getAdd="getAdd"></dataShow>
       </div>
      <!-- 视频列表弹框 -->
      <div class="videoWin2">
          <videoWin></videoWin>
      </div>
      <!-- 视频放大 -->
      <div id="aloneWin">
          <aloneVideoWin></aloneVideoWin>
      </div>
     <iframe id="model3D" :src="unityUrl" frameborder="0" width="100%" height="100%" scrolling="no" />
  </div>
</template>

<script>

import aloneVideoWin  from './aloneVideoWin'
import videoWin  from './aloneVideo'     //视频弹框菜单
import  dataShow  from  './dataShow'

export default {
  name: 'model3D',
  components: {
    aloneVideoWin,
    videoWin,
    dataShow
  },
  data(){
      return{
          unityUrl:'',
          closeShow:'0',
      }
  },
  //创建
  created(){
    this.$bus.$on('largeWin', this.largeWin);  //接收放大的命令
  },

  //安装
  mounted(){
    setTimeout(()=>{
      this.unityUrl = this.unityPATH;
    }, 500)

    window.addEventListener('message', this.recieve)
  },
  
  //方法
  methods:{

    recieve(e){
      console.log('e', e);
      this.$bus.$emit('getTargetId',e.data.handle);  //发送id到图表
      
      if(e.data.handle){
        this.closeShow = 1;
      }
    },

    // 控制曲线的
    getAdd(e){
        this.closeShow = 0;  //关闭视频窗口
    },

    // 放大缩小曲线窗口
    largeWin(e){
        var Dom  = document.getElementById('largeWin')
        if(e  == 1){
            Dom.style.width = "100%";
            Dom.style.marginLeft = "0px";
            Dom.style.left = "0px";
        }else{
            Dom.style.width = "1326px";
            Dom.style.marginLeft = "-663px";
            Dom.style.left = "50%";
        }

    },

  }


}
</script>
<style  scoped lang="scss">
    .u3d{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    #model3D{
        width: 100%;
        height: 100%;
        margin-left: -24px;
    }

    #aloneWin{
      position: absolute;
      width: 1184px;
      z-index: 1;
      left: 50%;
      margin-left: -592px;
      margin-top: -262px;
      top: 50%;
    }


    
    // 视频弹框
    .videoWin1{
        position: absolute;
        // width: 495;
        // height: 585px;
        z-index: 1;
        // right: 0;
        left: 50%;
        margin-left: -592px;
        margin-top: -262px;
        top: 50%;
    }

    .a{
        z-index: 99;
    }
    .b{
        z-index: -1;
    }

    .videoWin2{
          position: absolute;
          right: 0;
    }

     // 图表弹框
    .dataWin{
        position: absolute;
        width: 1326px;
        height: 575px;
        left: 50%;
        margin-left: -663px;
        top: 50%;
        margin-top: -262px;
        // z-index: 1;
    }

</style>