<template>
<!-- u3d视频 -->
  <div class="aloneVideo">
        <div class="win1" v-if="win1 == 1" >
            <div class="winTitle box justify">
                    <div>桥梁监测物列表</div>
                    <!-- <div class="pointer" @click="winBottom">下拉</div> -->
            </div>
            <div class="mT10 mL20 mR20 mB10">
                <!--   options-->
                <el-select v-model="value" value-key="id" placeholder="请选择" @change="changeTarget">
                    <el-option-group
                        v-for="group in options"
                        :key="group.id"
                        :label="group.name">
                        <el-option
                            v-for="item in group.list"
                            :key="item.id"
                            :label="item.videoName"
                            :value="item">
                        </el-option>
                        </el-option-group>    
                </el-select>
            </div>

            <div  class="videoBox pointer">
                <!-- controls  -->
                <video id="myVideo" class="video-js vjs-default-skin" muted width="100%" heigt="270px" @click="videoWin()">
                    <source :src="src" type="application/x-mpegURL" />
                </video>
            </div>
            <div class=" videoBox1Fu box wrapFlex">
                <div  v-for="(item,index) in videoList" :key="index"  class="videoBox1">
                    <video :id="'myVideoList' + index" style="" class="video-js vjs-default-skin video"  muted @click="changeVideo(item)">
                        <source :src="item.videoUrl" type="application/x-mpegURL" />
                    </video>
                </div>
            </div>
        </div>
  </div>
</template>

<script>

import videojs from 'video.js';

const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};

export default {
  name: 'aloneVideo',
//   directives: {clickoutside},

  data(){
      return{
          value:'',
          options:[],
          videoList:[],
          win1:0,        // 0隐藏弹框 1显示弹框
          closeVideo:'0',   //0关闭视频弹框  1打开
          src:'',
          projectId:'',
      }
  },
  //创建
  created(){
      this.projectId = sessionStorage.getItem('projectId')
  },

  //安装
  mounted(){
      window.addEventListener('message', this.recieve)     //d监听u3d事件
  },
  
  //方法
  methods:{
      recieve(e){
          console.log('e', e);
          if(e.data.state){
              this.tvShow();
            //   this.videoData();    //单桥的视频
            //   this.win1 = 1;
          }
      },


    // 视频请求
    videoData(){
        console.log('请求数据');
        
        var path = `pandect/select/bridge/video?bid=${this.projectId}&videoName=`;
        this.$axios.post(`${this.baseURL}${path}`).then(res => {
             console.log('获取视频数据', res.data.data);

            var data = res.data.data;
            var arr = [];

            data.forEach((item, index)=>{
                    var num = item.list;
                    num.forEach((item1, index1)=>{
                        arr.push(item1)
                    })
            })
            this.videoList = arr;
            
            // console.log('this.videoList', this.videoList);
            
            this.src = this.videoList[0].videoUrl;    //播放主视频
            this.$nextTick(()=>{
                var player  = videojs('myVideo');
                player.play();
            })
            this.videoList.forEach((item, index)=>{    //遍历播放播放多个视频
                this.$nextTick(()=>{
                    let player  = videojs('myVideoList'+ index);
                    player.play();
                })
            })

            this.options  = data;  //视频搜索下拉框

        }).catch(err=>{ 
        })
    },

     // 修改主视频播放
    changeVideo(item){
        console.log('item', item);
        this.src = item.videoUrl;
        var player  = videojs('myVideo');
        videojs('myVideo').src(this.src)
        player.play();
    },


    changeTarget(item){
        console.log('item', item);
        
        this.value = item.videoName;      // id
        this.src = item.videoUrl;

        this.tvShow();          //关闭视频
        // this.closeVideo = 1;    //弹出窗口
        this.$bus.$emit('closeVideo', 1);  //打开视频弹框    aloneVideoWin
        this.$bus.$emit('aloneSrc', this.src);  //路径发给视频弹框

    },



    // 监视器是否显示
    tvShow(){
        // if(this.win==1)this.win=0;
        if(this.win == 1){
            this.win = 0
        }

        if(this.win1 == 0){
            console.log('111');
            
            this.videoData();  //获取视频数据
            this.win1 = 1;
        }else{
            console.log('关闭');
            var player  = videojs('myVideo');
            player.dispose();
            this.videoList.forEach((item, index)=>{
                let player  = videojs('myVideoList'+ index);
                player.dispose();    //dispose销毁
            })
            this.win1 = 0;
        }
    },

    handleClose(e) {
        console.log('点击空白处');
        if(this.win1 == 1){
            var player  = videojs('myVideo');
            player.dispose();
            this.videoList.forEach((item, index)=>{
                let player  = videojs('myVideoList'+ index);
                player.dispose();    //dispose销毁
            })
            this.win1 = 0;
        }
    },


    // 点击视频跳转到
    videoWin(){
       this.tvShow();
       this.$bus.$emit('closeVideo', 1)
       this.$bus.$emit('aloneSrc', this.src)
    },








  }


}
</script>
<style  scoped lang="scss">
    .aloneVideo{
        width: 100%;
        height: 100%;
    }

    ::v-deep .win1{
        position: absolute;
        right: 89px;
        width: 494px;
        top: 100px;
        z-index: 1;
        border-radius: 8px;
        border: 1px solid #0C49AF;
        background: rgba($color: #000000, $alpha: 0.4);
        .winTitle{
            height: 40px;
            line-height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 8px 8px 0px 0px;
            background: linear-gradient(128deg, #03195B 0%, #0C49AF 100%);
        }
        .videoBox{
            // margin-left: 5px;
            // margin-right: 5px;
            width: 100%;
            height: 270px;
            object-fit: fill;
            .myVideo-dimensions{
                width: 100%;
                height: 270px;
            }
        }
        .videoBox1Fu{
            width: 100%;
            padding: 8px 5px 10px 5px;
        }
        .video{
            width: 160px !important;
            height: 90px !important;
        }
        .videoBox1{
            width: 160px;
            height: 90px;
            margin-bottom: 10px;
            .myVideo-dimensions{
                width: 160px;
                height: 90px;
            }
        }
        .el-select{
            width: 100%;
            height: 40px;
            border-radius: 20px;
            .el-input{
                height: 40px;
            }
            .el-input__inner{
                border-radius: 20px !important;
            }
        }
    }






</style>